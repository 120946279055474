span.badge.bg-warning {
  background-color: $orange !important;
}

span.badge.notification-icon {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  padding: 5px 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -5px;
  bottom: -7px;
}

.bell {
  position: absolute;
  top: 2px;
  right: 9px;
}

.status-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.status-badge {
  background-color: #ffc000 !important;
}

.bg-all {
  background-color:  $btn-color-1; /* #fff2cc */
}

.bg-complete {
  background-color: #92d050 !important;
}

.bg-draft {
  background-color: $btn-color-2; /* #ffe599 !important ; */
}

.bg-quote {
  background-color: #000 !important; 
}

.bg-status {
  background-color: #ffc000 !important;
}

.bg-production {
  background-color: #f5f5f5;
  color: black;
}

.bg-review {
  background-color: $btn-color-3; /* #ffe599 !important; */
  color: black;
}

.bg-hold {
  background-color: $btn-color-3; /* #ffd966 !important; */
  color: black;
}

.bg-progress {
  background-color: $btn-color-4;
}

.bg-delivered {
  background-color: $btn-color-5;
}

.bg-sold {
  background-color: $btn-color-6;
}
.bg-cf-success {
  background-color: $cf-success !important;
}
.bell:hover {
  color: #999999;
}

.essential-badge {
  width: 36px;
  height: 36px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.20);
  margin: 3px !important;
  padding: 8px;
}

.essential-badge-incomplete {
  background-color: #da4f49 !important;
}
.essential-badge-complete {
  background-color: #5bb75b !important;
}
.essential-badge-pending {
  background-color: #ff9900 !important;
}
.black-pill-border {
  border: 2px solid #000 !important;
}