.form-col-even {
    background-color: $pale-yellow;
}

.form-col-even-quote {
    background-color: $pale-blue;
}

.owner-img > img {
    height: 80px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid grey;
    border-radius: 16px;
    padding: .5rem;
}

textarea.comment-textarea {
    height: 120px;
    margin-bottom: 10px;
}
.form-control, .form-select, .input-group-text{
    color: $grey!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: normal!important;
    padding: 10px!important;
    border-color: $light-grey;
}
.input-group-sm > .btn,
.input-group-sm > .form-control{
    border-radius: 6px;
}