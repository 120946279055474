button#showCalc {
    border: none;
    width: 100%;
    max-width: 320px;
    padding: 0.5rem 3rem;
    font-size: 1.2rem;
    background-color: #fff;
    color: $link-color;

    img {
        width: 100%;
    }
}
/*
div.carousel-inner div.carousel-item {
    text-align: center;
}
div.carousel-inner div.carousel-item img {
    margin: 0 auto;
    margin-bottom: 100px;
}
a.carousel-control.left, a.carousel-control.right {
    background-image: none;
}
div.carousel-caption {
    font-size: 14pt;
    background-color: #666666;
}
*/
.grid{
    display: flex;
    flex-wrap: nowrap;
    max-width: 62%;
    margin: 40px -18px;
    .grid-item{
        border: 0;
        border-radius: 16px;
        margin: 0 18px;
        min-height: 372px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        padding: 24px;
        transition: .3s all;
        &.card-1{
            background: url(../img/Card.jpg) no-repeat center bottom;
            background-size: cover;
        }
        &.card-2{
            background: url(../img/Card-1.jpg) no-repeat center bottom;
            background-size: cover;
        }
        &.card-3{
            background: url(../img/Card-2.jpg) no-repeat center bottom;
            background-size: cover;
            color: $shedpro-blue;
        }
        &.card-4{
            background: url(../img/Card-3.jpg) no-repeat center bottom;
            background-size: cover;
            color: $shedpro-blue;
        }
        &:hover{
            transition: .3s all;
            transform: scale(1.05);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        }
    }
    @media screen and (max-width: 1500px){
        margin: 40px 0;
        max-width: 100%;
    }
}