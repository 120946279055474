#topmostnav{
  > .container-fluid{
    justify-content: flex-end;
  }
}
#mainNav {
  background-color: $white;
  @media (max-width:767px) {
    justify-content: flex-end;
  }
  [aria-controls="mobileMenuDropdown"]{
    @media (max-width:767px) {
      margin: 0 24px 13px;
    }
  }
}
#topmostnav{
  padding: 19px 12px;
}
#mainNav2 {
  background-color: $med-dark-grey;
}

#mainNav {
  border-bottom: 2px solid $shedpro-blue;
  padding-bottom: 0;
}

#mainNav a.nav-link,
#mainNav button.nav-link-btn {
  color: $dark-grey;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#mainNav a.nav-link{
  padding: 12px 16px;
  display: flex;
  align-items: center;
  &.active{
    path{
      fill: #fff;
    }
  }
  &:hover{
    path{
      fill: #fff;
      transition: .3s all;
    }
  }
}
path{
  transition: .3s all;
}
#mainNav button.nav-link-btn {
  border: none;
}
#mainNav a{
  border-radius: 6px 6px 0 0;
  @media (max-width: 767px) {
    border-radius: 0;
  }
}
#mainNav a:hover,
#mainNav a.active {
  background-color: $shedpro-blue;
  color: #ffffff;
  text-decoration: none;
  transition: .3s all;
}

.navbar-brand{
  // position: absolute;
  // left: 24px;
  // top: 50%;
  // transform: translate(0, -50%);
  margin-left: -12px;
  img{
    max-height: 80px;
  }
}

#userMenu {
  font-size: 0.9rem;
}

#userMenu,
#userMenu a {
  color: $shedpro-blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  display: flex;
    align-items: center;
}
.header-search{
  max-width: 600px;
}
#userMenu a {
  text-decoration: none;
}

a#signoutLink {
  color: #00b3d8;
}
.user-avatar{
  width: 40px;
  height: 40px;
  margin-right: 17px;
}
.navbar-nav{
  @media (max-width: 1220px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    display: block;
  }
}
.searchCommentArea {
  text-align: center;
  @media only screen and (max-width: 1600px) {
    position: inherit;
  }
}
.nav-item{
  svg{
    margin-right: 10px;
  }
  +.nav-item{
    margin-left: 4px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
}
