div.followup-icons > img {
  padding: 4px;
  margin: 2px;
}

.table.details-table > :not(:first-child) {
  border: 1px solid black;
}

table.details-table tr {
  border: 1px solid #E0E0E0;
}

// table.details-table th {
//   color: white;
//   background-color: black;
// }

.status-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-badge-padding {
  padding-bottom: 3px;
}

.status-center {
  text-align: center !important;
}

table.details-table th,
table.details-table td {
  padding: 4px;
}
label.form-label {
  margin-bottom: 0.1rem;
}
div.current-phase {
  div {
    padding-top: 8px;
    font-weight: bold;
    display: inline-block;
  }
}
div.breadcrumb {
  background-color: whitesmoke;
  border-radius: 12px;
  margin-bottom: 6px;
}
div.breadcrumb-quote {
  background-color: $pale-blue;
  border-radius: 12px;
  margin-bottom: 6px;
}
.header-text {
  font-size: 20pt;
  font-weight: 600;
  color: #2c3b64;
}
.badge-large {
  font-size: 16px !important;
  font-weight: bold;
  padding: 6px 12px !important;
  margin-left: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.badge-primary, .badge-clown {
  background-color: #999999;
  color: #000000;
}
.badge-clown.active {
  background-color: #FFFF00;
  color: #FF0000;
}
.badge-primary.active {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
div.star-box {
  width: 30px;
  float: left;
  text-align: center;
}
.invalid {
    background-color: $lightPink;
    /*color: #FFFFFF !important;*/
}
input:disabled.invalid {
    background-color: $lightPink;
    /*color: #FFFFFF !important;*/
}
.alert-warning.invalid {
  background-color: orange;
  color: #000;
  font-weight: bold;
}
.invalidSelect__control {
  background-color: $lightPink !important;
}
div.gigantic {
 font-weight: bold;
 font-size: 2rem;
 color: $blue;
 text-align: center;
 height: 100%;
 padding-top: 30px;
 background-color: $med-grey;
}
.total-payments {
  font-weight: bold;
}
.contract-popover {
  color: #FAAD73;
}
.cf0
{
    background-color: #9936AB !important;
    color: $white;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 3px 8px;
}
.cf1
{
    background-color: #aeaaaa !important;
    border-color: #aaaaaa !important;
    color: #000000 !important;
}
.cf2 {
    background-color: #fff2cc !important;
    border-color: #fff2cc !important;
    color: #000000 !important;
}
.cf3
{
    background-color: #ffe599 !important;
    border-color: #ffe599 !important;
    color: #000000 !important;
}
.cf4
{
    background-color: #ffd966 !important;
    border-color: #ffd966 !important;
    color: #000000 !important;
}
.cf5
{
    background-color: #ffc000 !important;
    border-color: #ffc000 !important;
    color: #000000 !important;
}
.cf6
{
    background-color: #92d050 !important;
    border-color: #92d050 !important;
    color: #FFFFFF !important;
}
.cf7
{
    background-color: #000000 !important;
    border-color: #000000 !important;
    color: #FFFFFF;
}
.cf8
{
    background-color: #767171 !important;
    border-color: #767171 !important;
    color: #FFFFFF;
}
.cf9 {
    background-color: #FFFFFF !important;
    color: #000000;
    /*border-color: #767171 !important;
    background-color: #B9DAE9 !important;
    border-color: #B9DAE9 !important;
    color: #FFFFFF;*/
}
.datePickerDiv {
  width: 140px;
  float: left
}

.quote-bgc {
    background-color: #E1F1FF !important; //#0074CC
}

.quote-pricing-info {
    text-align: center !important;
    color: #686C70 !important;
    font-style: italic;
    font-weight: bold;
}
th.quote-pricing-view-title {
  text-align: center !important;
  font-weight: bold;
  background-color: $very-light-blue !important;
  color: $dark-grey !important;
}
.quote-expired-title {
    font-weight: bold;
    font-style: italic;
    color: #6c1616
}
td.num-value {
  text-align: right !important;
}
span.company-panel > img {
    border: solid 1px #999999;
    width: 220px;
    padding: 6px 18px;
    cursor: pointer;
    border-radius: 8px;
}

span.company-panel.active > img {
    border: solid 3px #0000FF !important;
}

.description-small {
  font-size: small !important;
  font-style: italic;
}
.web-verify-header {
  font-weight: bold;
  font-size: 1.1rem;
}
.web-verify-panel {
  background-color: #F5F5F5;
  margin: 0 24px;
  padding: 16px 12px;
}
.highlight-text{
  background-color: #FFFF00 !important;
}
.promo-check-small {
  font-size: 13px !important;
  font-style: italic;
  background-color: #E1F1FF;
}