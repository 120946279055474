#advSearch {
  border: 0;
  .card-body{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

tr.view-contract > td.clickable,
tr.view-inventory > td.clickable {
  cursor: pointer;
}

tr.view-contract > td.clickable:hover,
tr.view-inventory > td.clickable:hover {
  background-color: $yellow;
}

tr.sortable-header-row > th {
  cursor: pointer;
}

tr.sortable-header-row > th:hover {
  background-color: #f1f1f1;
}

td.essential-icons > a > img {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  padding: 2px;
  margin: 1px;
}

td.followup-icons {
  padding: 0;
  text-align: center;
  position: relative;
/*
  display: flex;
  flex-direction: column;
  align-items: center;
*/
}

td.followup-icons img {
  margin: 1px;
}
