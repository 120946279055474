#toolbar {
  background-color: $white;
  border-radius: 10px;
  border-bottom: 1px solid #E0E0E0;
  padding: 16px;
  .btn {
    border-radius: 3px;
    margin: 1px;
    font-weight: 500;
  }
}
