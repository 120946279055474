.document-brief {
  // border: solid 2px #000000;
  border-radius: 4px;
  background-color: #2c3b64;
  color: #ffffff;
  padding: 15px;
  margin-bottom: 4px;
  cursor: pointer;
}

div.document-brief:hover,
div.document-brief.active {
    // background-color: #ff6d4d;
    color: #ff7048;
    border-color: #FF0000;
}

a.report-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

div.report-dsc {
  margin-left: 12px;
}

div.report-header {
  font-weight: bold;
  font-size: 1.6rem;
}
table#user-parameters-table {
  width: 100%; 
  margin-top: 8px;
}
.runButtonPDF, .runButtonXLS, .runButtonCSV
{
  background-position: 130px 0px;
  background-color:#F5F5F5;
  background-repeat: no-repeat;
  font-size:10pt;
  font-weight:bold;
  text-decoration:none;
  cursor:pointer;
  display:block;
  float:right;
  margin:6px 6px 6px 6px;
  width:170px !important;
  text-align:center;
  padding:8px 4px 8px 8px;
  border:solid 1px #999999;
  span {
    margin-top: 6px;
    display: block;
  }
}
.runButtonPDF:hover,.runButtonXLS:hover, .runButtonCSV:hover 
{
  color:Red;
  background-color:#FFF;
  border:solid 1px #000000;
}
.runButtonPDF
{
  background-image: url("../img/pdf.png");
}
.runButtonXLS 
{
  background-image: url("../img/excel.png");
}
.runButtonCSV 
{
  background-image: url("../img/csv.jpg");
}
.report-status-badge {
  display: inline-block;
  padding: 3px 8px;
  font-size: 12pt;
  font-weight: bold;
}
.report-status-badge.active {
  color: #00FF00;
}
.report-status-badge.inactive {
  color: #FF0000;
}
.btn-group.tiny {
  button {
    padding: 2px 14px;
  }
}