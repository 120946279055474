.new-element {
  //box-shadow: 0px -1px 28px -6px #0275d8;
  border-color: #0275d8;
}


.build-step-card {
  background-color: #fdfdfd;
}

.build-steps-drag:hover {
  cursor: pointer;
}
.card{
  border-radius: 10px;
  background: $white;
  padding: 16px;
  border: 0;
  margin-top: 10px;
  .card-header{
    background: transparent;
    padding: 0 10px 16px;
    h3{
      color: $shedpro-blue!important;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      margin: 0!important;
    }
  }
}
.card-footer{
  background: transparent;
  border: 0;
  padding: 0;
}