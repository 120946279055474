/* SHEDPRO THEMED COLORS */

$shedpro-blue: #2B3B63;
$shedpro-orange: #FF7048;

/*************************/

$blue: #2B3B63;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$lightPink: #ffcccc;
$red: #ff0000;
$orange: #fd7e14 !default;
$green: #5bb75b;
$light-grey: #E4E5E9;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$dark-grey: #5E6578;
$grey: #787E90;
$grey-bg: #F4F5F7;

$med-grey: #d9d9d9;
$med-dark-grey: #999999;
$link-color: #00b3d8;

$btn-color-1: #fff2cc;
$btn-color-1-active: #8a7d66;
$btn-color-2: #ffe599;
$btn-color-3: #ffd966;
$btn-color-4: #ffc000;
$btn-color-5: #92d050;
$btn-color-6: #127a20;
$btn-color-7: #f5f5f5;
$btn-color-8: #405844;
$btn-color-9: #00809E;

$very-light-blue: #e4f6fd;
$light-blue: #d0e1f3;
$med-light-blue: #6b9de2;

$cf-yellow: #FCEFCE;
$cf-success: #8EC44C;
$pale-yellow: #ffeecc;

$pale-blue: #E1F1FF;
$white: #ffffff;