.btn-warning {
  background-color: #faa732;
  border-color: #d08002;
}

button.btn-delete {
  background-color: #da4f49;
  border: #dc3c35;

}
button.btn-delete:hover {
  background-color: #b91f19;
}

button.secondary-nav {
  background-color: #5c636a;
  color: #efffff;
}

button.sub-navigation-dropdown {
  background-color: #5c636a;
  color: #ffffff;
}

button.btn-yellow {
  background-color: $cf-yellow;
  border-color: $cf-yellow;

  :hover {
    background-color: $yellow;
    border-color: $yellow;
  }
}

button.btn-admin-dropdown {
  color: white;
}

button.btn-admin-dropdown:hover {
  background-color: #006d91;
  color: white;
}

button.btn-red {
    background-color:#da4f49;
}
.signout img{
  margin-right: 6px;
}
.signout:hover,
.signout:active,
.signout:focus,
.signout{
  border: 0;
  box-shadow:none;
  background-color: transparent;
  color: $dark-grey;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.286%;
  padding: 0;
}
.btn-search:focus,
.btn-search:hover{
  background-color: transparent;
  border-color: $light-grey;
}
.btn-search{
  background: transparent;
  border-left: 0;
  border-color: $light-grey;
}
Button.button-round {
    border-radius: 0.25rem;
}
.searchCommentArea input[type="text"]:focus{
  box-shadow: none;
  border-color: $light-grey;
}
.searchCommentArea input[type="text"]{
  border-right: 0;
  min-width: 600px;
  @media only screen and (max-width: 1600px) {
      min-width: auto;
  }
}
Button.btn-bell {
  border-radius: 6px;
  background: rgba(43, 59, 99, 0.10);
  border: 0;
  padding: 10px;
  position: relative;
}

Button.btn-bell:hover {
    background-color: #e5e5e5;
    border-color: transparent;
}

Button.btn-bell:active {
    background-color: transparent;
    border-color: transparent;
}

button.btn-inv-context {
    color: white;
}

button.btn-inv-context:hover {
    background-color: #006d91;
    color: white;
}

button.btn-blue {
    background-color: #5bc0de;
    border-color: #00b3d8;
}
button.btn-blue:hover {
    background-color: #48afce;
}

button.btn-comment-none{
    background-color: $med-grey;
    border-color: $med-dark-grey;
    :hover {
        background-color: $med-dark-grey;
        border-color: $med-grey;
    }
}
.bg-cf-yellow {
  background-color: #FCC016 !important;
}
.bg-orange {
    background-color: #F47D22 !important;
}
.bg-cf-red {
  background-color: #F47D22 !important;
  border-color: #F47D22 !important;
}


.btn.btn-tb-0 {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #000;
}
.btn.btn-tb-1 {
  background-color: $btn-color-1;
  border-color: $btn-color-1;
  color: #000;
}

.btn.btn-tb-2 {
  background-color: $btn-color-2;
  border-color: $btn-color-2;
  color: #000;
}

.btn.btn-tb-3 {
  background-color: $btn-color-3;
  border-color: $btn-color-3;
  color: #000;
}

.btn.btn-tb-4 {
  background-color: $btn-color-4;
  border-color: $btn-color-4;
  color: #000;
}

.btn.btn-tb-5 {
  background-color: $btn-color-5;
  border-color: $btn-color-5;
  color: #000;
}

.btn.btn-tb-6 {
  background-color: $btn-color-6;
  border-color: $btn-color-6;
  color: #FFF;
}

.btn.btn-tb-7 {
  background-color: $light-blue;
  border-color: $btn-color-7;
  color: #000;
}

.btn.btn-tb-8 {
  background-color: $btn-color-8;
  border-color: $btn-color-8;
  color: #FFFFFF;
}

.btn.btn-tb-9 {
  background-color: $btn-color-9;
  border-color: $btn-color-9;
  color: #FFFFFF;
}

.btn.btn-serial-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.1rem;
  margin: 0;
  background-color: transparent;
  border-color: transparent;
  float: right;
}

.btn.btn-circle {
  position: fixed;
  z-index: 10;
}

.build-btn {
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    //padding: 0.375rem
}
.btn-calculator {
  color: $dark-grey;
  border-radius: 4px;
  border: 1px solid#E0E0E0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.286%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  position: absolute;
  right: 24px;
  bottom: 12px;
  &:active,
  &:focus,
  &:hover{
    box-shadow: none!important;
    background: transparent!important;
  }
  img{
    margin-right: 6px;
  }
  @media (max-width: 1220px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: flex;
    position: inherit;
    margin: -2px 16px 11px;
    border: 0;
  }
}
.btn-orange{
  background-color: $shedpro-orange;
  &:hover, &:active{
    background-color: #ff8968!important;
  }
}
.shedpro-btn-tb.btn-tb-active{
  background: $dark-grey;
  color: $white;
  border-color: $dark-grey;
}
.shedpro-btn-tb{
  border-radius: 34px;
  border: 1px solid #E0E0E0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 8px;
  background-color: transparent;
  color: $dark-grey;

}
.filter-buttons button{
  margin: 0 6px;
}
.year-filter .btn{
  border: 0;
}
.year-filter{
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: $white;
  color: $dark-grey;
  /* Desktop/Body 14 - Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  right: 244px;
  position: absolute;
  @media (max-width: 1600px) {
    top: 18px;
  }
}
.year-filter .btn{
  &:active,&:focus{
    margin-right: 0px;
    box-shadow: none;
    border: 0;
  }
}
.dropstart.arrow > button{
  background: url(../img/Arrow-down-light.png) no-repeat center center;
  height: 100%;
  &:hover{
    background-color: #E0E0E0;
  }
}
.btn-grey{
  border-radius: 4px;
  background: #EAECF0;
  padding: 10px 20px;
  color: $dark-grey;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.286%; 
  display: flex;
  align-items: center;
  &:hover{
    background: #f1f1f1;
  }
  img{
    margin-right: 6px;
  }
}
.filter-button{
  position: absolute;
  top: 17.5px;
  right: 16px;
}
.btn-dark{
  background: $shedpro-blue;
}
.cancel-btn{
  color: $dark-grey!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 6px;
  background: #EDEDED;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 0!important;
  svg{
    margin-left: 6px;
  }
  path{
    transition: .3s all;
  }
  &:hover{
    background: rgb(58, 58, 58);
    color: $white!important;
    path{
      fill: $white;
      transition: .3s all;
    }
  }
}
.btn-ghost{
  border-radius: 3px;
  background:#959DB1!important;
  color: $white!important;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3px 8px;
  border: 0;
}
.setting-btn:active,
.setting-btn:focus,
.setting-btn{
  background: transparent!important;
  border: 0!important;
  outline: none!important;
  box-shadow: none!important;
}
.setting-btn:hover{
  background: transparent;
}
.setting-btn:hover ellipse,
.setting-btn:hover circle{
  fill: $shedpro-orange;
  
}
.btn-group-light{
  .btn{
    &:hover{
      background: #f9f9f9 !important;
    }
    color: $dark-grey!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 700!important;
    line-height: 16px!important; /* 114.286% */
    background: transparent!important;
    border-radius: 4px!important;
    padding: 10px 12px!important;
    margin-right: 10px;
    border: 1px solid #E0E0E0!important;
  }
}
.more-btn{
  .btn{
    &:hover{
      background: #f9f9f9 !important;
    }
    color: $dark-grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.286%;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    border-radius: 4px!important;
    background: $white!important;
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    svg{
      margin-right: 6px;
    }
  }
}
.status-btn{
  border: 0;
  padding: 8px 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
  margin-left: 6px;
}
.sub-navigation-dropdown{
  margin-left: 3px;
}