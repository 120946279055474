@import "./_variables.scss";
#loginCard {
    width: 100%;
    max-width: 480px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 5% 3% 5%;
    color:#787E90;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    .login-header{
        img{
            max-width: 100%;
        }
        .login-description{
            margin: 24px 0;
        }
        text-align: center;
    }
    label{
        font-weight: 500;
    }
    .form-control{
        border-radius: 4px;
        border: 2px solid #DDD;
        padding: 12px 16px;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }
    .login-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            color:#5E6578;
            font-weight: 500;
            text-decoration: none;
            &:hover{
                color: $shedpro-orange;
            }
        }
        button{
            border: 0;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            border-radius: 4px;
            background   : $shedpro-blue;
            padding: 10px 24px;
            &:hover{
                background: $shedpro-orange;
            }
        }
    }
}
.login-bg{
    background-position: center left;
    background-size: cover;
    max-width: calc(100% - 480px);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
}