.bg-med-light {
    background-color: $gray-200 !important;
}

.bg-med-dark {
    background-color: $gray-300 !important;
}

.bg-med-darker {
    background-color: $gray-400 !important;
}

.bg-med-darkest {
    background-color: $gray-500 !important;
}

.bg-darker {
    background-color: $gray-600 !important;
}

.bg-light-blue {
    background-color: $light-blue !important;
}

.bg-med-light-blue {
    background-color: $med-light-blue !important;
}

.cursor-pointer {
    cursor: pointer;
}

.serial-container {
    display: flex;
    flex-direction: row;
    width: 12.3%;
    height: 100%;
    padding: .15rem;
    flex-grow: 1;
    flex-shrink: 1;
}

.serial-close {
    transform: rotate(45deg);
    color: #999999;
    width: 0.50rem !important;
}

.serial-close:hover {
    color: #0074cc;
}

.serial-close:active {
    color: #870b27
}

.serial-select {
    width: 98%;
    flex-shrink: 0;
    flex-grow: 0;
}

td.cell-highlight, th.cell-highlight {
    background-color: #FBFD8B !important;
}

td.bold-row {
    font-weight: bold;
}

.quil-text-area {
    height: 40%
}


.default-build-step {
    color: #161c2d;
}

.complete-build-step {
    color: $cf-success;
}

.incomplete-build-step {
    color: gray;
}

.in-progress-build-step {
    color: $orange;
}

.react-datepicker__input-container input[type="text"] {
    border-color: rgb(204, 204, 204);
    border-style: solid;
    border-width: 1px;
    padding: 3px;
    border-radius: 4px;
    width: 150px;
}
.accordion-body {
    display: block;
}

.download-link {
    font-weight: bold;
    color: #0000FF;
    text-decoration: underline;
    cursor: pointer;    
}
.xs {
    padding: 1px 6px !important;
}
.badge.large {
    font-size: 11pt;
    font-weight: normal;
    padding: 6px 16px;
    border-radius: 10px;
}
.badge-warning {
    background-color: #ffad33;
}
.badge-success {
    background-color:#5bb75b;
}
.badge-default {
    background-color: #999;
}
.btn-ghost, .badge-ghost, .label-ghost, .bg-ghost
{
    color: #000000 !important;
    background-color: #FFFFFF !important;
    background-image: none !important;
    border-color: #666 !important;
    border-width: 1px;
    border-style: solid;
}
.label-ghost
{
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    /* font-size: 8pt;*/
}
.btn-skeleton, .badge-skeleton, .label-skeleton
{
    color: #FFFFFF !important;
    background-color: #999999 !important;
    background-image: none !important;
    border-color: #000000 !important;
}
.label-skeleton
{
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
}
.label-royal, .badge-royal 
{
    background-color:#CC33FF !important;
    color: #FFF !important;
}
tr.private td:first-child {
    color: #FF0000;
    font-weight: bold;
}
tr.comment-row td {
    border-top: solid 1px #999;
    border-bottom-style: none;
}

.modal-on-top {
    z-index: 10;
}

.rtf.open .rtf--mb > * {
    transform: rotate(90deg) !important;
}

.dropdown-buttons {
  width: 190px !important;
  text-align: left !important;
  background-color: #f1c271 !important;
  color: black !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  border-radius: 3px !important;
  color: #583e11 !important;
  margin: 10px 0px 10px 0px !important;
}

.size-form-group {
    margin-bottom: 0px !important;
}

.description-text {
    color: #797B7B !important;
    font-style: italic;
}

.small-description-text {
    color: #797B7B !important;
    font-style: italic;
    font-size: small;
}
.mt-10{
    margin-top: 10px;
}
.modal-index {
    z-index: 2000;
}
.filter-display {
    font-style: italic;
    color: #6c757d !important;
}
.open-ext {
    right: 3px;
    &:hover{
        path{
            fill: $shedpro-orange;
        }
    }
    path{
        fill: $shedpro-blue;
        &:hover{
            fill: $shedpro-orange;
        }
    }
}
img.essential-icon {
    //width: 45px;
    width: 80%;
}

.yearDropdownMenu {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(-125px, 39px) !important;
}
.yearDropdownMenuCashSales {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(10px, 30px) !important;
}
.react-confirm-alert-overlay {
    z-index: 1100 !important;
}
#privacyTable tr td {
    border: 1px solid black;
    padding: 12px;
}
.center-text {
    text-align: center !important;
}